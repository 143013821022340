<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback2 />
        {{ $t('payment report') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate='addPayload' />
      <v-row class='px-2'>
        <v-col cols='12' md='6' class='py-0' lg='3' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='branchSelection'
            :items='branchList'
            outlined
            dense
            :label="$t('select_branch')"
            item-text='shop_name'
            :no-data-text="$t('no_data')"
            item-value='shop_id_pri'
          ></v-select>
        </v-col>
<!--        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model.trim='typeSelection'
            :items='typeList'
            outlined
            dense
            :label="$t('listType')"
            item-text='name'
            :no-data-text="$t('no_data')"
            item-value='value'
          ></v-select>
        </v-col>-->
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        item-key='number'
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >

        <template v-slot:[`item.order_id`]='{ item }'>
          <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.order_id }}
          </span>
        </template>
        <template v-slot:[`item.order_create`]='{ item }'>
          {{ item.order_create | sumdatetime }}
        </template>
        <template v-slot:[`item.sum_check_df`]='{ item }'>
          <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_check_df }}
          </span>
        </template>
        <template v-slot:[`item.sum_course_df`]='{ item }'>
          <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_course_df }}
          </span>
        </template>
        <template v-slot:[`item.sum_drug`]='{ item }'>
          <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_drug }}
          </span>
        </template>
        <template v-slot:[`item.sum_course`]='{ item }'>
        <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_course }}
          </span>
        </template>
        <template v-slot:[`item.sum_member`]='{ item }'>
        <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_member }}
          </span>
        </template>
        <template v-slot:[`item.sum_all`]='{ item }'>
        <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.sum_all }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import branch from '@/api/branch'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportPayment2, removeComma } from '../useExcel2'
import { sumdate, sumdatetime } from '@/plugins/filters'
import Comeback2 from '../Comeback2.vue'
import store from '@/store/app'
export default {
  components: {
    DateFilters,
    Comeback2,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const XLSX = require('xlsx')
    const courseGroupSelect = ref('')
    const courseGroupList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('branch'), value: 'shop_name', width: 200 },
      { text: i18n.t('date'), value: 'order_create', width: 180 },
      { text: i18n.t('firstname_lastname'), value: 'customer_fullName', width: 250 },
      { text: i18n.t('a_number'), value: 'order_id', width: 120 },
      // { text: i18n.t('checkCostDF'), value: 'sum_check_df', width: 150, align: 'end vertical' },
      { text: i18n.t('checkCost'), value: 'sum_course_df', width: 150, align: 'end vertical' },
      { text: i18n.t('drugCost'), value: 'sum_drug', width: 150, align: 'end vertical' },
      { text: i18n.t('courseCost'), value: 'sum_course', width: 150, align: 'end vertical' },
      { text: i18n.t('memberCost'), value: 'sum_member', width: 150, align: 'end vertical' },
      {
        text: i18n.t('total'),
        value: 'sum_all',
        width: 150,
        align: 'end vertical',
      },

    ])
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const header = ref('')
    const payload = ref({})
    const exportLoading = ref(false)
    const branchList = ref([])
    const branchSelection = ref('')
    const typeSelection = ref(0)
    const typeList = ref([
      {
        name: i18n.t('all'),
        value: 0,
      },
      {
        name: i18n.t('commission'),
        value: 1,
      },
      {
        name: i18n.t('dfCost'),
        value: 2,
      },
    ])

    branch
      .branchListForSelection()
      .then(res => {
        branchSelection.value = res[0].shop_id_pri
        branchList.value = res
      })
      .catch(error => {
        console.log('error : ', error)
      })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        main_shop_id_pri: branchSelection.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const { data } = await reportPayment2(payload)
      dataTableList.value = data

      header.value = `${i18n.t('payment report')} ${i18n.t('detail_at_day')} ${i18n.t('since')} ${sumdate(
        start,
      )} ${i18n.t('to')} ${sumdate(end)}`

      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${header.value}.xlsx`
      const Heading = [
        [header.value],
        [
          '#',
          `${i18n.t('branch')}`,
          `${i18n.t('date')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('a_number')}`,
          // `${i18n.t('checkCostDF')}`,
          `${i18n.t('checkCost')}`,
          `${i18n.t('drugCost')}`,
          `${i18n.t('courseCost')}`,
          `${i18n.t('memberCost')}`,
          `${i18n.t('total')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, await removeComma(JSON.parse(JSON.stringify(dataTableList.value))), {
        header: [
          'number',
          'shop_name',
          'order_create',
          'customer_fullName',
          'order_id',
          // 'sum_check_df',
          'sum_course_df',
          'sum_drug',
          'sum_course',
          'sum_member',
          'sum_all',
        ],

        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
      ]
      const wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols

      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('payment report')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    watch([branchSelection], () => {
      addPayload(payload.value.start, payload.value.end)
    })

    return {
      exportLoading,
      courseGroupSelect,
      columns,
      dataTableList,
      loading,
      options,
      courseGroupList,
      mdiFileExcelOutline,
      branchSelection,
      typeSelection,
      typeList,
      branchList,
      addPayload,
      store,
      exportExcel,
    }
  },
}
</script>
<style>
.vertical {
  border-left: 1px solid rgba(50, 50, 50, 0.14);
  height: 43px;
}

.theme--light.v-data-table th {
  border-top: thin solid rgba(50, 50, 50, 0.14);
  color: rgba(50, 50, 50, 0.87) !important;
  border-bottom: thin solid rgba(50, 50, 50, 0.14);
}
</style>
